import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import AboutUs from '../assets/AboutUs.svg';
import UIimg from '../assets/UI.svg';
import Character from '../assets/Character.svg';
import ECommerce from '../assets/ECommerce.svg';


function AboutUsSection() {
    
    const handleSelect=(eventKey)=>{
        switch (eventKey) {
            case 'software':
                let charElement = document.getElementById('FamiliarImage');
                charElement.src = Character;
                break;
            case 'design':
                let uiElement = document.getElementById('FamiliarImage');
                uiElement.src=UIimg;
                break;
            case 'commerce':
                let ecomElement = document.getElementById('FamiliarImage');
                ecomElement.src=ECommerce;
                break;
          }
    }
    const softwareText="Leveraging Flutter's cross-platform capabilities, we develop apps that run seamlessly on iOS, Android, web, desktop, and embedded systems. We'll work with you from the project's inception, to delivery and maintenance. If you already have an app that needs work, we specialize in this too!";
    const designText="Our talented designers are experts at creating visually appealing and user-friendly interfaces for apps across all industries. We focus on crafting intuitive and engaging experiences that delight users across platforms.";
    const commerceText="We provide comprehensive E-commerce services, including design, development and optimization to help businesses create and grow their online stores. Our team of experts ensures a seamless and successful experience for our clients.";
    return (
        <div >
            <Row id='FirstRowAbout'>
                <Col sm={12} md={6}>
                    <img src={AboutUs} id='aboutUsImage'/>
                </Col>
                <Col id='aboutUsContainer'>
                    <p className='PaddingSm'><span className='Display-L Gray900'>About </span>
                    <span className='Display-L Purple400' >us </span></p>
                    <p id='aboutUsContent' className='PaddingSm Subtitle-SF Gray500 Subtitle-SF'>Picnic is a leading software development company based in Mexico. Founded in 2021 by a team of both American and Mexican professionals, Picnic provides world class talent consisting of Mobile & Web developers, UI/UX designers and E-commerce experts. We strive to deliver adaptive software solutions that drive our clients' business growth.</p>
                </Col>
            </Row>

            <Row className='SecondRowAbout' >{/* should hide at 1507px and below */}
                
                <Col id='SecondRowFC'>
                    <div id='FamiliarTitle'>
                        <p className='Display-L'>
                            <span>Get familiar with our </span>
                            <span className='Purple400'>exciting</span>
                        </p>
                        <p  className='Display-L'>
                            <span>software services</span>
                        </p>
                    </div>
                    <Tabs
                        defaultActiveKey="software"
                        id="uncontrolled-tab"
                        className="mb-3 nav-fill"
                        onSelect={handleSelect}
                    >
                        <Tab className='Button-SF AboutTab' eventKey="software" title="Software Development" id='tab1'>
                            <p className='familiarText Subtitle-SF Gray500' >{softwareText}</p>
                        </Tab>
                        <Tab className='AboutTab' eventKey="design" title="Product Design" id='CentralTab'>
                            <p className='familiarText Subtitle-SF Gray500'>{designText}</p>
                        </Tab>
                        <Tab className='AboutTab' eventKey="commerce" title="E-commerce" >
                            <p className='familiarText Subtitle-SF Gray500'>{commerceText}</p>
                        </Tab>
                    </Tabs>
                </Col>
                <Col id='SecondRowSC'>
                    <img src={Character} id='FamiliarImage'/>
                </Col>
            </Row>
            <div className='Text-Left PaddingSm'  id='RowAux'>{/* This should hide when reaching 767 px wide and below*/}
                <p>
                    <span className='Display-M Gray900'>We provide </span>
                    <span className='Display-M Purple400'>exciting</span>
                    <span className='Display-M Gray900'> software solutions.</span>
                </p>
            </div>
            <Row className='SecondRowMd'>{/* This should hide when reaching 767 px wide and below*/}
                <Col><img src={Character} id='FamiliarImage'/></Col>
                <Col>
                    <p className='Display-M Text-Left Gray900' id='Researchtext'>Software Development</p>
                    <p className='Subtitle Gray600 Text-Left'>{softwareText}</p>
                </Col>
            </Row>
            <Row className='SecondRowMd'>{/* This should hide when reaching 767 px wide and below*/}
                <Col>
                    <p className='Display-M Text-Left Gray900' id='Researchtext'>Product Design</p>
                    <p className='Subtitle Gray600 Text-Left'>{designText}</p>
                </Col>
                <Col><img src={UIimg} id='FamiliarImage'/></Col>
            </Row>
            <Row className='SecondRowMd'>{/* This should hide when reaching 767 px wide and below*/}
                <Col><img src={ECommerce} id='FamiliarImage'/></Col>
                <Col>
                    <p className='Display-M Text-Left Gray900' id='Researchtext'>Ecommerce</p>
                    <p className='Subtitle Gray600 Text-Left'>{commerceText}</p>
                </Col>
            </Row>


            <Row className='d-block d-md-none d-lg-none d-xl-none'>
                <Col className='PaddingSm'>
                    <div className='Text-Left PaddingSm' id='ExcitingSwSolTxt'>
                        <span className='Display-M Gray900'>We provide </span>
                        <span className='Display-M Purple400'>exciting</span>
                        <br/>
                        <span className='Display-M Gray900'>software solutions.</span>
                    </div>
                </Col>
            </Row>
            <Row className='d-block d-md-none d-lg-none d-xl-none'>
                <div>
                    <Col sm={12} md={6}>
                        <img src={Character} id='aboutUsImage'/>
                    </Col>
                        {/* <img
                            src={Character}
                            id='SmCharImg'
                        /> */}
                </div>
                <div>
                    <p className='Display-M Text-Left Gray900' id='Researchtext'>Software Development</p>
                </div>
                <div>
                    <p className='PaddingSm Text-Left Subtitle Gray600'>
                        Leveraging Flutter's cross-platform capabilities, we develop apps that run seamlessly on iOS, Android, web, desktop, and embedded systems. We'll work with you from the project's inception, to delivery and maintenance. If you already have an app that needs work, we specialize in this too!
                    </p>
                </div>
                <div>
                    <Col sm={12} md={6}>
                        <img src={UIimg} id='aboutUsImage'/>
                    </Col>
                    {/* <img
                        src={UIimg}
                        id='SmCharImg'
                    /> */}
                </div>
                <div>
                    <p className='Display-M Text-Left Gray900' id='Researchtext'>Product Design</p>
                </div>
                <div>
                    <p className='PaddingSm Text-Left Subtitle Gray600'>
                        Our talented designers are experts at creating visually appealing and user-friendly interfaces for apps across all industries. We focus on crafting intuitive and engaging experiences that delight users across platforms.
                    </p>
                </div>
                <div>
                    <Col sm={12} md={6}>
                        <img src={ECommerce} id='aboutUsImage'/>
                    </Col>
                    {/* <img
                        src={ECommerce}
                        id='SmCharImg'
                    /> */}
                </div>
                <div>
                    <p className='Display-M Text-Left Gray900' id='Researchtext'>Ecommerce</p>
                </div>
                <div>
                    <p className='PaddingSm Text-Left Subtitle Gray600'>
                        We provide comprehensive E-commerce services, including design, development and optimization to help businesses create and grow their online stores. Our team of experts ensures a seamless and successful experience for our clients.
                    </p>
                </div>
            </Row>
            
        </div>
      );
    }
    
    export default AboutUsSection;
    