import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Mail from '../assets/Mail.svg';
import Phone from '../assets/Phone.svg';

function Footer() {
    return (
        <div>
        <Row><p id='LogoFooter' className='Display-L Purple600'> picnic</p></Row>
        <Row className='FooterContainer d-none d-xs-none d-sm-none d-md-flex'>
            <Col className='Footer1' md={6}>
                <p className='Display-L WeLove'>We love to code.</p>
                <p className='Subtitle Gray600'>
                    Here at Picnic, we're passionate about making our clients' ideas spring to life. So let's take that first step towards making your project a reality. 
                </p>
                <p id='phoneP'><a className='Copy Gray600 LinkAnchor TextButton' href="tel:33-1839-0209"><img src={Phone} id='phone'/>+52(33) 1599 6024</a></p>
                <p><a className='Copy Gray600 LinkAnchor TextButton' href = "mailto: herrmartell@gmail.com"><img src={Mail} id='mail'/>project@picnic.dev</a></p>
            </Col >
            <Col className='Footer2'>
                <div  class="d-inline-flex p-2 Footer2Container" id>
                    <p className='BoldCopy' id='company'>Company</p>
                    <p><a className='Copy Gray600 LinkAnchor TextButton' href='#heroContainer'>Home</a></p>
                    <p><a className='Copy Gray600 LinkAnchor TextButton' href='#FirstRowAbout'>About us</a></p>
                </div>
            </Col>
            <Col className='Footer2'>
                <div class="d-inline-flex p-2 Footer2Container"id='FooterF'>
                    <p className='BoldCopy' id='greet'>Say Hi &#9996;</p>
                    <p ><a className='Copy Gray600 LinkAnchor TextButton' href='https://www.linkedin.com/company/picnic-dev/'>Linkedin</a></p>
                    <p ><a className='Copy Gray600 LinkAnchor TextButton' href='https://twitter.com/picnic_dev'>Twitter</a></p>
                </div>
            </Col>
        </Row>
        <Row className='FooterContainer d-flex d-xs-flex d-sm-flex d-md-none'>
            <Col className='Footer1' md={6}>
                <p className='Display-L WeLove'>We love to code.</p>
                <p className='Subtitle Gray600'>
                    Here at Picnic, we're passionate about making our clients' ideas spring to life. So let's take that first step towards making your project a reality. 
                </p>
                <p id='phoneP'><a className='Copy Gray600 LinkAnchor TextButton' href="tel:33-1839-0209" ><img src={Phone} id='phone'/>+52(33) 1599 6024</a></p>
                <p ><a className='Copy Gray600 LinkAnchor TextButton'  href = "mailto: herrmartell@gmail.com"><img src={Mail} id='mail'/>project@picnic.dev</a></p>
            </Col >
            <div id='SmFooterAuxContainer'>
                <div className='Footer2'>
                    <div  class="d-inline-flex p-2 Footer2Container">
                        <p className='BoldCopy' id='company'>Company</p>
                        <p><a className='Copy Gray600 LinkAnchor TextButton' href='#SmHeroContainer'>Home</a></p>
                        <p><a className='LinkAnchor Copy Gray600 TextButton' href='#FirstRowAbout'>About us</a></p>
                    </div>
                </div>
                <div className='Footer2'>
                    <div class="d-inline-flex p-2 Footer2Container"id='FooterF'>
                        <p className='BoldCopy' id='greet'>Say Hi &#9996;</p>
                        <p ><a className='Copy Gray600 LinkAnchor TextButton' href='https://www.linkedin.com/company/picnic-dev/'>Linkedin</a></p>
                        <p ><a className='Copy Gray600 LinkAnchor TextButton' href='https://twitter.com/picnic_dev'>Twitter</a></p>
                    </div>
                </div>
            </div>
            
        </Row>
        <Row id='final'></Row>
        </div>
    );
}
    
export default Footer;