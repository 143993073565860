import Accordion from 'react-bootstrap/Accordion';
import { Row, Col } from 'react-bootstrap';

function FAQ() {
  return (
    <div className='FAQSection' id='FAQContainer'>
      <h2 className='Display-XL'>FAQs</h2>
      <Row>
        <Col md={{span:8, offset:2}} sm={12} xs={12} >
          <Accordion defaultActiveKey="" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header className='FAQHeader Title'>I’ve sent you an email via your contact box on your website, when can I expect a reply?</Accordion.Header>
            <Accordion.Body className='Subtitle Gray600 Text-Left'>
              You will be receiving a reply from us within a maximum of 24 business hours from the moment you contacted us.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header className='FAQHeader Title'>I want to build a project from the ground up, what do I need to send you?</Accordion.Header>
            <Accordion.Body className='Subtitle Gray600 Text-Left'>
              First thing you should do is schedule a call with us, there we’ll talk about related documents such as: requirements, timeline, designs, mockups, etc.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header className='FAQHeader Title'>I can’t call your contact number, is there another way I can reach you?</Accordion.Header>
            <Accordion.Body className='Subtitle Gray600 Text-Left'>
              You can reach us via email at project@picnic.dev, via direct message on our website contact section; or you can simply schedule a call to talk to us in the same section!
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header className='FAQHeader Title'>Can you sign an NDA before working with us?</Accordion.Header>
            <Accordion.Body className='Subtitle Gray600 Text-Left accordionBody'>
              Yes, we are comfortable signing NDA’s should you need us to before starting a project. We also sign contracts once project specifications are established with both our local and international clients. 
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
      
    </div>
  );
}

export default FAQ;