import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {Button} from 'react-bootstrap';
import { useState } from 'react';
import { send } from 'emailjs-com';

function ContactForm() {
  const [toSend, setToSend]=useState({
    name:'',
    email:'',
    description:'',
  });
  
  const onSubmit = (e) =>{
    if(this.name != '' && this.email!='' && this.description != ''){
      e.preventDefault();
      send(
        'service_j15tila',
        'template_sqn0wtm',
        toSend,
        'TkYcSPL2_ricXwLg9'
      )
      .then((response) => {
        alert('Message sent, we will contact within the next 24 business hours.');
        setToSend({
          name:'',
          email:'',
          description:'',
        })
      })
      .catch((err) => {
        alert('Failed to send message. Try again later.');
      });
    }
  }

  const handleChange = (e) =>{
    setToSend({...toSend, [e.target.name]:e.target.value});
  }
    return (
      <div id='ContactForm'>
        <p 
          className='Display-XL d-none d-xs-none d-sm-none d-md-block' 
          id='LetsTalk'
        >
          Let’s talk about<br/>your project
        </p>
        <p 
          className='Display-XL d-block d-xs-block d-sm-block d-md-none' 
          id='LetsTalk'
        >
          Let’s talk about<br/>your project
        </p> 
        <div className='FormSection1 d-none d-xs-none d-sm-none d-md-flex'>
          <Col></Col>
          <Col 
            className='FormRow' 
            id='black'
          >
            <p className='BoldCopy' >Full Name</p>
            <Form.Control 
              type="text"
              name='name'
              placeholder="Eg: Alexander Wallace Escobar" 
              md={6}
              value={toSend.name}
              onChange={handleChange}
            />
          </Col >
          <Col className='FormRow'>
            <p className='BoldCopy'>Email</p>
            <Form.Control 
              type='email'
              name='email'
              placeholder="contact@email.com" 
              value={toSend.email}
              onChange={handleChange}
              md={6} 
            />
          </Col>
          <Col></Col>
        </div>
        <div className='FormSection1 d-block d-xs-block d-sm-block d-md-none'>
          
          <Col 
            className='FormRow' 
            id='black'
          >
            <p className='BoldCopy' >Full Name</p>
            <Form.Control 
              type="text"
              name='name'
              placeholder="Eg: AlexanderWallace Escobar" 
              md={6}
              value={toSend.name}
              onChange={handleChange}
            />
          </Col >
          <Col className='FormRow'>
            <p className='BoldCopy'>Email</p>
            <Form.Control 
              type='email'
              name='email'
              placeholder="contact@email.com"
              value={toSend.email}
              onChange={handleChange}
              md={6} 
            />
          </Col>
          <Col></Col>
        </div>


        <Col md={{span: 6, offset: 3}} sm={12} className='TellUsSection'>
          <span className='BoldCopy' id='TellUsText'>Tell us about your project</span>
          <Form.Control
            type='text'
            name='description'
            value={toSend.description}
            onChange={handleChange}
            id='TellusArea'
            className='Textarea'
            as="textarea" 
            rows={12}
            placeholder="Please provide a brief explanation about your project"
          />
        </Col>
        
        <Row id='FormRow' >
            <Col className='FormRowAux'>
              <Button variant='link' id='SendButton'onClick={onSubmit}><span className='Button'>Send message</span></Button>
            </Col>
            <Col className='FormRowAux' id='RushTextContainer'>
              <p className='Subtitle' id='RushText'>Or if you're in a rush</p>
            </Col>
            <Col className='FormRowAux'>
              <Button id='CallBtn' href='https://calendly.com/d/4kw-99q-xc3/initial-consultation-call'>Schedule a call</Button>
            </Col>
        </Row>
      </div>
    );
  }
    
  export default ContactForm;