import './App.css';
import HeroSection from './Components/HeroSection';
import AboutUsSection from './Components/AboutUsSection';
import WhyPicnic from './Components/WhyPicnic';
import Testimonials from './Components/Testimonials';
import FAQ from './Components/FAQ';
import ContactForm from './Components/ContactForm';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <HeroSection></HeroSection>
      <AboutUsSection></AboutUsSection>
      <WhyPicnic></WhyPicnic>
      <Testimonials></Testimonials>
      <FAQ></FAQ>
      <ContactForm></ContactForm>
      <Footer></Footer>
    </div>
  );
}

export default App;
