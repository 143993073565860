import { Row } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Photo1 from '../assets/Photo1.png';
import Photo2 from '../assets/Photo2.png';

function Testimonials() {
    return (
        <div id='TestimonialsContainer'>
            <p className='Display-XL Gray900' id='TestimonialTitle'>Testimonials</p>

            <Row id='TestContainer' className='d-none d-xs-none d-sm-none d-md-flex d-lg-flex-d-xl-flex'>
                <Col className='TestCardCont shadow mb-5 bg-white rounded' id='TestRight'>
                    <div className=' TestCardHeader'>
                        <div className='PortraitContainer'>
                            <img src={Photo1}/>
                        </div> 
                        <div className='CharacterTextContainer'>
                            <p className='Display-M CharacterName'>Santiago Villagomez</p>
                            <p className='Subtitle CharacterTitle Purple900'>CEO. Energía Real</p>
                        </div>
                    </div>
                    <div className='TestimonyTextContainer'>
                        <p className='Copy-SF Gray600'>
                            Working with Picnic has simply been reliable. They understand the features we've needed to implement in our software and have consitently delivered their projects to us on the agreed timeline.
                        </p>
                    </div>
                </Col>
                <Col className='TestCardCont shadow mb-5 bg-white rounded' id='TestLeft'>
                    <div className=' TestCardHeader'>
                    <div className='PortraitContainer'>
                            <img src={Photo2}/>
                        </div> 
                        <div className='CharacterTextContainer'>
                            <p className='Display-M CharacterName'>Gabriela Moreno</p>
                            <p className='Subtitle CharacterTitle Purple900'>CFO. Intense 24</p>
                        </div>
                    </div>
                    <div className='TestimonyTextContainer'>
                        <p className='Copy-SF Gray600'>
                        We needed a project developed fast, but that didn't completely break our budget. Picnic was the only company that really adapted to our constraints. We'll be happy to continue working with them in the future.
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='d-block d-xs-block d-sm-block d-md-none d-lg-none-d-xl-none'>
                <Col>
                    <div className='SmCardTest shadow mb-5 bg-white rounded'>
                        <div className='CharSlot'>
                            <div>
                                <img src={Photo1} className='SmCharPortrait'/>
                            </div> 
                            <div className='DataSection'>
                                <p className='Display-M Purple600'>Santiago</p>
                                <p className='Display-M Purple600'>Villagomez</p>
                                <p className='Subtitle-SF Purple900'>CEO. Energía Real</p>
                            </div>
                        </div>
                        <div className='TestimonyTextContainer'>
                            <p className='Copy Gray600 Copy-SF'>
                            Working with Picnic has simply been reliable. They understand the features we've needed to implement in our software and have consitently delivered their projects to us on the agreed timeline.
                            </p>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='SmCardTest shadow mb-5 bg-white rounded'>
                        <div className='CharSlot'>
                            <div>
                                <img src={Photo2} className='SmCharPortrait'/>
                            </div> 
                            <div className='DataSection'>
                                <p className='Display-M Purple600'>Gabriela</p>
                                <p className='Display-M Purple600'>Moreno</p>
                                <p className='Subtitle Purple900'>CFO. Intense 24</p>
                            </div>
                        </div>
                        <div className='TestimonyTextContainer'>
                            <p className='Copy Gray600 Copy-SF'>
                            We needed a project developed fast, but that didn't completely break our budget. Picnic was the only company that really adapted to our constraints. We'll be happy to continue working with them in the future.
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
    
    export default Testimonials;