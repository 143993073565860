import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Money from '../assets/Money.svg';
import Clock from '../assets/Clock.svg';
import Globe from '../assets/Globe.svg';
import Dev from '../assets/Dev.svg';
import Monitor from '../assets/Monitor.svg';
import Briefcase from '../assets/Briefcase.svg';

function WhyPicnic() {
    return (
        <div id='WhyContainer'>
            <div width='100%'>
                <p className="text-center" id='WhyTitle'>
                    <span className='Display-L Gray900'>Why </span>
                    <span className='Display-L Purple400' id='WhyTitle'>Picnic</span>
                    <span className='Display-L Gray900'>?</span>
                </p>
            </div>
            
            <Row className='WhyRow'>
                <Col sm={12} md={6} lg={4} className='CardDivcontainer' >
                    <div className='CardDiv'>
                        <img src={Money}/>
                        <p className='cardTitle Display-M Gray900'>Cost efficient</p>
                        <p className='cardText Copy Gray600 Copy-SF'>We know how important it is for your project to be delivered within budget. By basing our company in Mexico, we can deliver world class projects at significantly lower rates than the industry standard.</p>
                    </div>
                </Col> 
                <Col sm={12} md={6} lg={4} className='CardDivcontainer' >
                    <div className='CardDiv'>
                        <img src={Clock}/>
                        <p className='cardTitle Display-M Gray900'>On-time</p>
                        <p className='cardText Copy Gray600 Copy-SF'>Using the Agile Scrum methodology, we deliver timely results with a transperent development cycle, meaning you'll have a 360° view of your project's progress and constant input opportunities.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4} className='CardDivcontainer' >
                    <div className='CardDiv'>
                        <img src={Monitor}/>
                        <p className='cardTitle Display-M Gray900'>Adaptive solutions</p>
                        <p className='cardText Copy Gray600 Copy-SF'>Our team's wide range of software expertise allows us to provide you with the best tailor-made solutions that align with your business goals.</p>
                    </div>
                </Col>
            
                <Col sm={12} md={6} lg={4} className='CardDivcontainer' >
                    <div className='CardDiv'>
                        <img src={Globe}/>
                        <p className='cardTitle Display-M Gray900'>Nearshore advantage</p>
                        <p className='cardText Gray600 Copy-SF'>Having our whole team geographically adjacent to the US results in multiple advantages. Aside from sharing the same work schedules, we also have the same communication standards and work culture, making for seamless collaboration.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4} className='CardDivcontainer' >
                    <div className='CardDiv'>
                        <img src={Dev}/>
                        <p className='cardTitle Display-M Gray900'>Modern development</p>
                        <p className='cardText Copy Gray600 Copy-SF'>Making sure your project is aligned with the most successful development trends is big priority for us. We constantly study a wide range of industry software trends to make sure the service we provide to our clients is innovative and exciting.</p>
                    </div>
                </Col>
                <Col sm={12} md={6} lg={4} className='CardDivcontainer' >
                    <div className='CardDiv'>
                        <img src={Briefcase}/>
                        <p className='cardTitle Display-M Gray900'>Extensive experience</p>
                        <p className='cardText Copy Gray600 Copy-SF'>Your project is our top priority, which is why we make sure our in-house team is built around the best senior talent available. This includes our project managers, who adhere to strict KPI's while overseeing the succesful development of large and complex projects.</p>
                    </div>
                </Col>
            </Row>
        </div>
      );
    }
    
    export default WhyPicnic;