import Logo from '../assets/Logo-white.svg'
import { Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import {Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

function PicnicNavbar() {
    
  return (
    <Col id='NavBarContainer'>
      <div>
        <img src={Logo} id='NavBarLogo'/>
      </div>
      <div className='black'>
            <Nav className='me-auto'>
              <a className='navlink Copy-SF' href='#FirstRowAbout' id='AboutUsText'><span className='TextButton'>About us</span></a>
              <a className='navlink Copy-SF' href='#SecondRowAbout' id='ServicesText'><span className='TextButton'>Services</span></a>
              <a className='navlink Copy-SF' href='#WhyContainer' id='WhyPicnicText'><span className='TextButton'>Why Picnic?</span></a>
              <a className='navlink Copy-SF' href='#TestimonialsContainer' id='TestimonialsText'><span className='TextButton'>Testimonials</span></a>
              <a className='navlink Copy-SF' href='#FAQContainer' id='FAQsText'><span className='TextButton'>FAQ's</span></a>
              <Button variant='link' id='navbarButton' href='#ContactForm'>Contact us</Button>
            </Nav>
      </div>
    </Col>
  );
}

export default PicnicNavbar;