import PicnicNavbar from './PicnicNavbar';
import { Button } from 'react-bootstrap';
import Logo from '../assets/Logo-white.svg';

function HeroSection() {
    return(
      <>
        <div className='HeroSection'>
          <div id='heroContainer'>
            <div id='navContainer'>
              <PicnicNavbar></PicnicNavbar>
            </div>
            <div id='HeroTxtContainer'>
              <span className='Display-XXL White HeroText'>We </span><span className='Display-XXL Purple300 HeroText'>&lt;/develop&gt;</span><br/>
              <span className='Display-XXL White HeroText'>ideal business</span><span className='Display-XXL White HeroText' id='solutions'> solutions.</span>
            </div>
            
            <p id='heroParagraph' className='Subtitle-SF' >Whether you're building a project from the ground up, or looking for that expert input
              <br/>to get your app running to perfection, we're here to help your business grow.</p>
            <Button href='#ContactForm' id='heroButton'><p id='HeroButtonText'>Contact us</p></Button>
            
          </div>
        </div>
        <div className='SmHeroSection'>
          <div className='SmHeroContainer' id='SmHeroContainer'>
            <img src={Logo} id='SmLogo'/>
          </div> 
          <div className='SmHeroTitleSection '>
            <p className='Display-XL White'>We</p>
            <p className='Display-XL Purple300'>&lt;/develop&gt;</p>
            <p className='Display-XL White'>all you can</p>
            <p className='Display-XL White'>imagine.</p>
          </div>
          <div id='SmHeroPContainer'>
            <p id='SmHeroParagraph' className='White Subtitle-SF'>Whether you're building a project from the ground up, or looking for that expert input to get your app running to perfection, we're here to help your business grow.</p>
          </div>
          <div id='CUBtn'>
            {/* <button className='Purple400Button Button shadow' href='#LetsTalk'>Contact us</button> */}
            <Button href='#ContactForm' id='SmHeroButton'><p id='HeroButtonText'>Contact us</p></Button>
          </div>
        </div>
      </>
    )
    
  }
  
  export default HeroSection;